/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDocuments {
    &-Wrapper {
        max-width: 740px;

        .MyAccountStatusMessage {
            max-width: 740px;
            margin-block-end: 30px;
        }

        .Form {
            .FieldForm {
                &-Section {
                    width: 100%;

                    &WithSpace {
                        @include mobile {
                            margin-block-start: 0;
                        }

                        &:not(:first-child) {
                            legend {
                                margin-block-start: 40px;
                            }
                        }
                    }

                    &.identity {
                        .MyAccountConfirmIdForm {
                            margin-block-start: 30px;
                        }

                        .FieldFileAdvanced-Buttons {
                            .Button {
                                @include mobile {
                                    width: auto;
                                }
                            }
                        }
                    }
                }

                &-Legend {
                    font-weight: 700;
                    font-size: 16px;
                    border-width: 0;
                }

                &-Actions {
                    text-align: center;
                }
            }
        }
    }

    &-Introduction {
        margin-block-end: 30px;
    }

    &-Submit {
        margin-block-start: 20px;

        @include desktop {
            width: 100%;
            max-width: 300px;
        }
    }

    &-HelpMessage {
        --paragraph-color: var(--primary-error-color);
        --paragraph-color-mobile: var(--paragraph-color);
        --paragraph-font-weight: 400;

        > * {
            margin-block-start: 35px;
        }
    }
}
