/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --my-account-overlay-optins-background: var(--secondary-light-color);
}

.MyAccountOverlay {
    &-ForgotPassword {
        text-decoration: underline;
    }

    &-Legend {
        &.optins {
            position: relative;
            margin-top: 20px;
            margin-bottom: 10px;
            padding: 20px;

            &::before {
                @include full-cover(true);

                background-color: var(--my-account-overlay-optins-background);
                opacity: .5;

                @include mobile {
                    @include full-page-cover(true);
                }
            }

            > p {
                margin-bottom: 0;

                &:not(:first-child) {
                    margin-top: 15px;
                }

                b {
                    font-weight: 600;
                }
            }

            .MyAccountOverlay-Checkbox {
                margin-right: 50px;
                font-size: 12px;
            }
        }

        &.agreement,
        &.gdpr {
            padding-inline: 20px;
        }
    }

    &-Checkbox {
        margin-block: 10px 10px;
    }

    &-SignUpButton {
        margin-top: 10px;
    }
}
