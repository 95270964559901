/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountInformation {
    &-Wrapper {
        .MyAccountStatusMessage {
            max-width: 740px;
            margin-block-end: 30px;
        }

        .Form {
            .FieldForm {
                &-Body {
                    max-width: 740px;

                    input[type='checkbox'] {
                        width: 100%;
                    }

                    input[disabled] {
                        background-color: var(--color-gray);
                        cursor: not-allowed;
                    }
                }

                &-Fields {
                    display: grid;
                    grid-template-columns: 1fr;
                }

                &-Section {
                    width: 100%;

                    &WithSpace {
                        @include mobile {
                            margin-block-start: 0;
                        }

                        legend {
                            margin-block-start: 40px;
                        }
                    }

                    &.identity {
                        .MyAccountConfirmIdForm {
                            margin-block-start: 30px;
                        }

                        .FieldFileAdvanced-Buttons {
                            .Button {
                                @include mobile {
                                    width: auto;
                                }
                            }
                        }
                    }

                    @include wide-desktop {
                        &.account,
                        &.emailPassword {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-column-gap: 30px;

                            legend {
                                grid-column-start: span 2;
                            }
                        }

                        &.account {
                            .Field-Wrapper_type_checkbox {
                                grid-column-start: span 2;
                            }
                        }

                        &.emailPassword {
                            legend {
                                & + .Field-Wrapper_type_password {
                                    grid-column-start: span 2;
                                    max-width: calc(50% - 15px);
                                }
                            }
                        }
                    }
                }

                &-Legend {
                    font-weight: 700;
                    font-size: 16px;
                    border-width: 0;
                }

                &-NoSiret {
                    margin-block-start: 24px;

                    strong {
                        display: block;
                        font-weight: 400;
                        font-size: 12px;
                        padding-bottom: 8px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--input-color);
                    }
                }

                &-ModifyCompanyMessage {
                    display: flex;
                    align-items: flex-start;
                    margin-block-start: 24px;

                    &::before {
                        flex: 0 0 auto;
                        content: "";
                        display: block;
                        width: 17px;
                        height: 17px;
                        margin-inline-end: 6px;
                        background: url($path-img + 'info-red.svg') no-repeat center;
                        background-size: contain;
                    }

                    p {
                        margin-block-end: 0;
                        color: #676767;

                        &, a {
                            font-weight: 500;
                            font-size: 13px;
                        }
                    }
                }

                &-Actions {
                    text-align: center;
                }
            }
        }
    }

    &-Submit {
        margin-block-start: 20px;

        @include desktop {
            width: 100%;
            max-width: 300px;
        }
    }
}
