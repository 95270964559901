
@mixin desktop {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px)
        and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px)
        and (max-width: 1280px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 811px)
        and (max-width: 1279px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin very-wide-desktop {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px)
        and (max-width: 1024px)
        and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media(min-width: 811px)
        and (max-width: 1023px)
        and (-webkit-min-device-pixel-ratio: 1)
        and (orientation: landscape) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 810px) {
        @content;
    }
}

@mixin narrow-mobile {
    @media (max-width: 479px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin hoverable {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

