/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountMyWishlist {
    &-Products {
        display: block;
        inset-block-start: auto;

        @include mobile {
            margin-block-start: 25px !important;
            margin-block-end: 40px !important;
        }
    }

    &-NoProducts {
        margin-block: 25px 30px;
        text-align: center;

        @include desktop {
            margin-block: 50px;
        }
    }

    &-ActionBar {
        @include desktop {
            position: relative;
            inset-block-start: auto;
            inset-inline-end: auto;
            margin-block: 65px 40px;
        }

        @include mobile {
            inset-block-end: 0;
            padding-block-end: 16px;
        }
    }

    &-ActionBarActionWrapper {
        justify-content: center;
        column-gap: 15px;
        margin-block-end: 35px;
    }

    &-Back {
        padding-block: 15px;
    }

    &-BackButton {
        display: inline-flex;
        align-items: center;
        column-gap: 6px;
    }

    &-Heading {
        margin-block-end: 0.4em;

        @include mobile {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }
    }

    &-FilterBtn {
        @include filter-button();

        @include mobile {
            justify-content: center;

            .FilterIcon {
                display: none;
            }
        }
    }

    .CategorySort {
        @include mobile {
            .FieldSelect-SortSelect {
                letter-spacing: .1em;
            }
        }
    }

    &-Miscellaneous {
        @include mobile {
            margin-block-start: 25px;
            margin-inline: calc(var(--content-wrapper-padding-mobile) * -1);
        }
    }

    &-MiscellaneousLayoutWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            border-block: 1px solid var(--primary-divider-color);

            &::before {
                content: "";
                display: block;
                height: calc(100% - 8px);
                border-left: 1px solid var(--primary-divider-color);
                position: absolute;
                top: 4px;
                left: 50%;
            }
        }
    }

    &-LayoutWrapper {
        @include mobile {
            width: 100%;
            justify-content: center;

            > button {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-ShareWishlistButton {
        width: auto;

        .ShareIcon {
            margin-inline-end: 12px;
        }

        &:not([disabled]):hover .ShareIcon_isPrimary {
            fill: var(--primary-dark-color);
        }
    }

    &-RelatedProducts {
        padding-block: 30px;

        &::before {
            @include full-page-cover(true);

            background-color: var(--secondary-base-color);
            opacity: .45;
        }

        &Title {
            margin-block: 0 1.75em;
            text-align: center;
            text-transform: uppercase;
        }

        @include desktop {
            padding-block: 45px;
        }
    }

    &-PopupAttributes {
        @include desktop {
            .ExpandableContent {
                &-Button {
                    padding-block: 15px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &-PopupActions {
        padding: 15px;
        border-block-start: 1px solid var(--primary-divider-color);

        @include desktop {
            padding: 30px 0;
        }
    }

    &-PopupSubmitButton {
        width: 100%;
    }
}
