/* stylelint-disable */

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

/**
 * Font-size calcul
 */
@mixin font-size($pxsize, $line-height: true) {
    $pxsize: strip-unit($pxsize);
    $remsize: $pxsize / 10;

    font-size: $pxsize + px;
    font-size: $remsize + rem;

    @if $line-height {
        line-height: ($pxsize + 5) + px;
    }
}

@mixin no-txt(){
    font-size: 0;
    text-indent: 100%;
    overflow: hidden;
}

@mixin list-nostyle() {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 0;
    }
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}


@mixin line($width:0px, $height:0px, $bgcolor:transparent, $x:center, $y:bottom){
    content: "";
    display: block;
    width: $width;
    height: $height;
    background-color: $bgcolor;
    position: absolute;

    @if $x == left {
        left: 0;
        right: auto;
    } @else if $x == center {
        left: 50%;
        margin-left: - $width / 2;
        right: auto;
    } @else if $x == right {
        right: 0;
        left: auto;
    } @else {
        left: $x;
        right: auto;
    }

    @if $y == top {
        top: 0;
        bottom: auto;
    } @else if $y == center {
        top: 50%;
        margin-top: - $height / 2;
        bottom: auto;
    } @else if $y == bottom {
        bottom: 0;
        top: auto;
    } @else {
        top: $y;
        bottom: auto;
    }
}

@mixin arrow($width:8px, $border-width:3px, $color:var(--c-main), $direction:right){
    content: "";
    display: block;
    width: $width;
    height: $width;
    border-style: solid;
    border-width: $border-width $border-width 0 0;
    border-color: $color $color transparent transparent;

    @if $direction == right {
        transform: rotate(45deg);
    } @else if $direction == bottom {
        transform: rotate(135deg);
    } @else if $direction == left {
        transform: rotate(-135deg);
    } @else if $direction == top {
        transform: rotate(-45deg);
    } @else {
        transform: rotate(45deg);
    }
}


@mixin input-radio-custom($size:21px){
    [type="radio"] {
        position: absolute;
        margin: 0;
        opacity: 0;

        & + label {
            position: relative !important;
            display: inline-block;
            min-height: $size;
            padding: 0 0 0 $size + 10px;
            cursor: pointer;
            z-index: 1;
            font-weight: 400;
            line-height: $size;

            &:before {
                content: '';
                display: block;
                left: 0;
                top: -1px;
                position: absolute;
                width: $size;
                height: $size;
                border: 1px solid var(--c-input-border);
                background: #fff;
                z-index: 2;
                box-sizing: border-box;
                pointer-events: none;
                border-radius: 50%;
            }
            &:after {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                position: absolute;
                top: ceil($size / 2);
                left: ceil($size / 2);
                margin: -8px 0 0 -7px;
                z-index: 3;
                opacity: 0;
                pointer-events: none;
                border-radius: 50%;
                background: var(--c-input-checked);
            }
        }

        &:checked + label:after {
            opacity: 1;
        }
        &:focus {
            outline: none;
        }
    }
}

@mixin input-checkbox-custom($size:21px){
    [type="checkbox"] {
        position: absolute;
        margin: 0;
        opacity: 0;

        & + label {
            position: relative !important;
            display: inline-block;
            min-height: $size;
            padding: 0 0 0 $size + 10px;
            cursor: pointer;
            z-index: 1;
            font-weight: 400;
            line-height: $size;

            &:before {
                content: '';
                display: block;
                left: 0;
                top: -1px;
                position: absolute;
                width: $size;
                height: $size;
                border: 1px solid var(--c-input-border);
                background: #fff;
                z-index: 2;
                box-sizing: border-box;
                pointer-events: none;
            }
            &:after {
                content: '';
                display: block;
                width: 11px;
                height: 11px;
                position: absolute;
                top: ceil($size / 2);
                left: ceil($size / 2);
                margin: -7px 0 0 -6px;
                z-index: 3;
                opacity: 0;
                pointer-events: none;
                background: var(--c-input-checked);
            }
        }

        &:checked {
            & + label {
                &:after {
                    opacity: 1;
                }
            }
        }
        &:focus {
            outline: none;
        }
    }
}


/**
* Trigger hover effect on different pseudo-classes
* depending on touch or no-touch device
* => no-touch > on hover and on focus (by default, can be disable)
* => touch > on active, and when a class is added on the targeted element (optionnal)
*
* @param $focus (boolean) set to false to disable the hover effect on focus (no-touch only)
* @param $touchClass (string) the hover effect will be set if the targeted element has this class
*/
@mixin hover($focus: true, $touchClass: false){
    //.no-touchevents & {
    &:hover {
        @content;
    }
    @if $focus == true {
        &:focus {
            @content;
        }
    }
    //}
    .touchevents & {
        &:active {
            @content;
        }
        @if $touchClass != false {
            &.#{$touchClass} {
                @content;
            }
        }
    }
}


@mixin full-cover( $is-pseudo-elt: false ) {
    @if $is-pseudo-elt == true {
        content: "";
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/**
* Set an item 100% window width, even if located in a limited width wrapper
*/
@mixin full-page-cover( $is-pseudo-elt: false ) {
    @if $is-pseudo-elt == true {
        content: "";
    }
    position: absolute;
    top: 0;
    height: 100%;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
}


@mixin full-page-width(){
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}


@mixin img-cover() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin picture-cover() {
    @include full-cover();

    picture, img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


/**
* Floating form items
* if $class2 equals 'empty', the next item is cleared
*/
@mixin form-item-float($class1:&, $width1:50%, $class2:false, $width2:false) {
    @if $width2 == false {
        $width2: 100% - $width1;
    }

    .#{$class1} {
        float: left;
        margin-right: 30px;
        margin-top: 0;
        width: calc(#{$width1} - 15px);

        // Just in case we are on a form-wrapper and not a form-item
        > .form-item {
            margin-top: 0;
        }

        @if $class2 != false {

            @if $class2 == empty {

                & + * {
                    clear: both;
                }

            } @else {

                .#{$class2} {
                    float: left;
                    margin-top: 0;
                    width: calc(#{$width2} - 15px);

                    // Just in case we are on a form-wrapper and not a form-item
                    > .form-item {
                        margin-top: 0;
                    }

                    & + * {
                        clear: both;
                    }
                }
            }

        } @else {

            & + * {
                float: left;
                margin-top: 0;
                width: calc(#{$width2} - 15px);

                // Just in case we are on a form-wrapper and not a form-item
                > .form-item {
                    margin-top: 0;
                }

                & + * {
                    clear: both;
                }
            }

        }
    }
}


@mixin responsive-table(){
    &,
    tbody,
    tr,
    th,
    td {
        display: block;
    }
    thead {
        display: none;
    }
    td {
        padding: 0;


        &.has-rwd-label {
            &:before {
                content: attr(data-rwd-label) ' :';
                display: inline-block;
                vertical-align: top;
                min-width: 80px;
                font-weight: 400;
                color: color(text-dark);
            }
        }

        & + td {
            margin-top: .5em;
        }

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            margin-top: 1em;
            padding-right: 0;
            @include clearfix;
        }
    }
    tr {
        padding: 1em;
    }
}


@mixin responsive-video(){
    position: relative;
    padding-top: 56.25%;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


@mixin grid-item($cols: 1, $margin-item: 1.5%) {
    flex: 1 1 ((100% / $cols) - $margin-item * 2);
    max-width: ((100% / $cols) - $margin-item * 2);
}
@mixin grid-items($cols: 1, $margin-item: 1.5%) {
    display: flex;
    flex-wrap: wrap;

    margin-left: -$margin-item;
    margin-right: -$margin-item;

    > * {
        @include grid-item($cols, $margin-item);
        margin-left: $margin-item;
        margin-right: $margin-item;
        margin-bottom: $margin-item * 2;
    }
}


/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {
    // linear (?)
    /*$scrimCoordinates: (
      0: 1,
      19: 0.738,
      34: 0.541,
      47: 0.382,
      56.5: 0.278,
      65: 0.194,
      73: 0.126,
      80.2: 0.075,
      86.1: 0.042,
      91: 0.021,
      95.2: 0.008,
      98.2: 0.002,
      100: 0
    );*/

    // cubic-bezier(0.67, 0, 0.66, 1)
    $scrimCoordinates: (
        0: 1,
        12.5: 0.987,
        23.4: 0.951,
        32.9: 0.896,
        41: 0.825,
        48.1: 0.741,
        54.4: 0.648,
        59.8: 0.55,
        64.8: 0.45,
        69.4: 0.352,
        73.9: 0.259,
        78.3: 0.175,
        83: 0.104,
        88: 0.049,
        93.6: 0.013,
        100: 0
    );

    $hue: hue($startColor);
    $saturation: saturation($startColor);
    $lightness: lightness($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrimCoordinates {
        $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
        $stops: append($stops, $stop, comma);
    }

    background: linear-gradient(unquote($direction), $stops);
}


/**
Convert em value into px
 */
@function em2px($target, $context: $base-font-size-em) {
    @if $target == 0 { @return 0 }
    @return ($target / 1em) * ($context / 1em) * 16 + 0px;
}
/**
Fluid font-size between 2 breakpoints.
Font-size must be in px, use em2px() function to convert from em.
*/
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}
/**
Fluid value between 2 breakpoints (can be use for other than font-size)
(Font-size must be in px, use em2px() function to convert from em)
*/
@mixin fluid-val($property, $min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        #{$property}: $min-font-size;
        @media screen and (min-width: $min-vw) {
            #{$property}: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
        @media screen and (min-width: $max-vw) {
            #{$property}: $max-font-size;
        }
    }
}


/*
Fluid value - clamp version (2023)
Modifications: remove math.div and math.round, return px instead of rem
@see https://www.smashingmagazine.com/2022/10/fluid-typography-clamp-sass-functions/
 */
$fluid-default-min-bp: 375px;
$fluid-default-max-bp: 1560px;

@function f-round($number, $decimals: 0) {
    $n: 1;
    @if $decimals > 0 {
        @for $i from 1 through $decimals {
            $n: $n * 10;
        }
    }
    @return round($number * $n) / $n;
}

@function px-to-rem($px) {
    $rems: ($px / 16px) * 1rem;
    @return $rems;
}

@function fluid($min-size, $max-size, $min-breakpoint: $fluid-default-min-bp, $max-breakpoint: $fluid-default-max-bp, $unit: vw) {
    $slope: ($max-size - $min-size) / ($max-breakpoint - $min-breakpoint);
    $slope-to-unit: f-round($slope * 100, 2);
    //$intercept-rem: f-round(px-to-rem($min-size - $slope * $min-breakpoint), 2);
    $intercept-px: f-round(($min-size - $slope * $min-breakpoint), 2);
    //$min-size-rem: f-round(px-to-rem($min-size), 2);
    $min-size-px: f-round($min-size, 2);
    //$max-size-rem: f-round(px-to-rem($max-size), 2);
    $max-size-px: f-round($max-size, 2);
    //@return clamp(#{$min-size-rem}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size-rem});
    @return clamp(#{$min-size-px}, #{$slope-to-unit}#{$unit} + #{$intercept-px}, #{$max-size-px});
}


/**
 * Animated placeholder for images or divs (FB like)
 */
@mixin placeholder-animated($color: #eee) {
    background-color: $color;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(90deg, $color, #f4f4f4, $color);
        animation: placeHolderGradient 1s infinite ease-in-out;
    }
}



/*** SPECIFIC TO DRUPAL ***/

@mixin ajax-progress-full(){
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(#fff, .5);
    z-index: 3;
}
@mixin ajax-progress-full-page(){
    @include full-page-cover();
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, .5);
    z-index: 3;
}



/*** SPECIFIC TO CURRENT PROJECT ***/

/* stylelint-enable */

@mixin product-card-horizontal() {
    .ProductCard {
        &-Link {
            flex-direction: row;
            align-items: center;
        }

        &-FigureReview {
            flex: 0 0 25%;
            max-width: 25%;
        }

        &-ProductActions {
            display: none;
        }

        &-Content {
            padding-top: 0;
            padding-left: 15px;
        }

        &-Name {
            font-size: 15px;
            text-transform: none;
        }

        &-Footer {
            margin-top: 0;
        }
    }
}

@mixin wrap() {
    max-width: var(--content-wrapper-width);
    padding-inline: var(--content-wrapper-padding);
    margin-inline: auto;

    @include mobile {
        padding-inline: var(--content-wrapper-padding-mobile);
    }
}

@mixin back-link() {
    display: inline-flex;
    align-items: center;
    color: var(--default-color);
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;

    .ChevronIcon {
        margin-inline-end: 5px;
    }
}

@mixin filter-button() {
    flex: 0 0 auto;
    grid-column: 2;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;

    &:hover {
        span {
            color: var(--primary-base-color);
        }

        .FilterIcon {
            fill: var(--primary-base-color);
        }
    }
}

@mixin addToCartIconOnly() {
    flex: 0 0 auto;
    width: var(--product-card-btn-addtocart-width);
    height: var(--product-card-btn-addtocart-width);
    min-width: 0;
    transition-property: background-color, color, border, box-shadow;

    @include no-txt();

    &::before {
        content: "";
        display: block;
        width: calc(var(--product-card-btn-addtocart-width) / 2);
        height: calc(var(--product-card-btn-addtocart-width) / 2);
        position: absolute;
        top: 47%;
        left: 52%;
        transform: translate(-50%, -50%);
        background: url($path-img + 'cart.svg') no-repeat center;
        background-size: contain;
    }

    &::after {
        content: "+";
        display: block;
        width: 14px;
        height: 14px;
        padding-left: 2px;
        background: #fff;
        border-radius: 50%;
        color: var(--button-background);
        font-size: 17px;
        text-align: center;
        text-indent: 0;
        line-height: 14px;
        position: absolute;
        top: 70%;
        left: 70%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        transition-property: opacity;
        will-change: opacity;
        transition-timing-function: ease-in;
        transition-duration: .25s;
        opacity: 0;
    }

    span {
        display: none;
    }

    &:not([disabled]) {
        &:hover {
            height: var(--product-card-btn-addtocart-width);
            background-color: var(--button-background);
            border-color: var(--button-border);

            &::after {
                opacity: 1;
            }

            @include desktop {
                box-shadow: 0 0 0 2px var(--button-background);
            }
        }
    }

    .Loader {
        position: absolute;

        &-Scale,
        &-Main {
            width: 100%;
            height: 100%;
        }
    }
}

@mixin homepageSectionTitle() {
    margin-block-start: 0;
    margin-block-end: 1.3em;
    font-weight: 300;
    font-size: #{fluid(22px, 40px)};
    text-align: center;
}

@mixin _buttonWithArrowBase() {
    text-align: center;

    .LinkArrowIcon {
        opacity: 0;
        position: absolute;
        right: 14px;
    }

    @include desktop {
        span {
            transition: transform .25s ease-out;
            will-change: transform;
        }

        .LinkArrowIcon {
            transition: opacity .25s ease-out;
            will-change: opacity;
        }

        &:hover {
            span {
                transform: translateX(-20px);
            }

            .LinkArrowIcon {
                opacity: 1;
            }
        }
    }
}

@mixin buttonWithArrow() {
    --button-font-size: 12px;

    @include _buttonWithArrowBase();

    .LinkArrowIcon {
        fill: var(--button-color);
    }

    @include desktop {
        --button-padding: 50px;
        --button-hover-padding: 50px;
    }
}

@mixin buttonHollowWithArrow() {
    --hollow-button-color: var(--primary-dark-color);
    --hollow-button-border: var(--primary-dark-color);
    --button-font-size: 12px;

    @include _buttonWithArrowBase();

    .LinkArrowIcon {
        fill: var(--primary-dark-color);
    }

    @include desktop {
        --hollow-button-padding: 50px;
        --hollow-button-hover-padding: 50px;
    }
}

@mixin animation() {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

@mixin animation-slow() {
    -moz-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    -webkit-transition: all 1.5s ease;
    transition: all 1.5s ease;
}

@mixin outOfView($x: 0, $y: 0, $opacity: 0, $duration: 1s, $delay: 0s) {
    transform: translate($x, $y);
    opacity: $opacity;
    visibility: hidden;
    transition: transform $duration ease $delay, opacity $duration ease $delay;
}

@mixin inView($x: 0, $y: 0, $opacity: 1) {
    transform: translate($x, $y);
    opacity: $opacity;
    visibility: visible;
}
