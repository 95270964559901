/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LoginAccountPro {
    // ...
}

.LoginAccount {
    &_type_Pro {
        $login-account-pro-secondary-color: darken($pro-light-color, 5%);
        --login-account-secondary-color: #{$login-account-pro-secondary-color};
        //--button-background: var(--secondary-base-color);
        //--button-hover-background: var(--secondary-dark-color);
        //--button-hover-border: var(--secondary-dark-color);
        background-color: var(--pro-light-color);
    }
}
