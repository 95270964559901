/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDashboard {
    @include mobile {
        grid-gap: 0;
    }

    @include desktop {
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    &-LastOrder {
        font-size: 14px; // same as .MyAccountMyOrders-Table

        .TextPlaceholder {
            display: block;
            margin-block-end: 10px;

            &::after {
                display: block;
                height: 170px;

                @include desktop {
                    height: 185px;
                }
            }
        }
    }
}
