/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountMyOrdersTab {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
    padding-block: .3em;
    padding-inline: 2em;
    font-size: 11px;
    font-weight: 700;
    color: var(--color-black);
    text-transform: uppercase;
    letter-spacing: .05em;
    opacity: .5;
    cursor: pointer;

    &:not(:last-child) {
        &::after {
            content: "";
            height: 100%;
            border-inline-end: 1px solid var(--primary-divider-color);
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
        }
    }

    &_isActive {
        background-color: var(--color-white);
        opacity: 1;
    }

    &-Icon {
        flex-shrink: 0;
        display: block;
        width: 26px;
        height: 26px;
        margin-inline-end: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &#my-orders {
        .MyAccountMyOrdersTab-Icon {
            background-image: url('/style/images/clock.svg');
            background-size: auto;
        }
    }

    &#my-favorites-orders {
        .MyAccountMyOrdersTab-Icon {
            background-image: url('/style/images/order-status/is_favorite.svg');
        }
    }

    @include mobile {
        flex: 1 1 auto;
    }

    @include desktop {
        min-height: 60px;
        padding-inline-end: 3em;
        border-block: 2px solid transparent;

        &:not(:last-child) {
            &::after {
                height: 26px;
                inset-block-start: 50%;
                margin-block-start: -13px;
            }
        }

        &_isActive {
            border-block-end-color: var(--color-black);
        }
    }
}
