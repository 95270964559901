/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountProQuickOrder {
    .MyAccountDashboard & {
        padding: 20px;
        border: {
            width: 6px;
            style: solid;
            color: var(--secondary-light-color);
        }

        @include mobile {
            margin-inline: -16px;
            border-inline-width: 0;
        }

        @include desktop {
            padding: 30px;
        }
    }

    &-TextBlock {
        margin-block-end: 20px;
        text-align: center;
    }

    &-Title {
        margin-block: 0 0.5em;
        font-weight: 700;
        font-size: 16px;
    }

    &-Description {
        margin-block-end: 0;
        font-weight: 300;
        font-size: 14px;
    }

    &-SearchWrapper {
        &_isOpened {
            input {
                --input-border-color-focus: var(--input-border-color);

                border-end-start-radius: 0;
                border-end-end-radius: 0;
                border-block-end-color: transparent;
            }
        }
    }

    &-SearchInput {
        margin-block-start: 0;
    }

    &-ClearButton {
        display: none;
        cursor: pointer;
        position: absolute;
        /*inset-block-start: calc(50% - 7px);
        inset-inline-end: 15px;
        font-size: 11px;
        font-weight: 500;
        color: var(--link-color);
        text-decoration: underline;*/
        inset-block-start: calc(50% - 12px);
        inset-inline-end: 10px;

        &_isVisible {
            display: inline-flex;
        }
    }

    &-Suggested {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 20px;
        background-color: var(--color-white);
        border: 1px solid var(--input-border-color);
        border-block-start-width: 0;
        border-radius: 0 0 var(--input-border-radius) var(--input-border-radius);
        position: absolute;
        inset-block-start: var(--input-height);
        inset-inline-start: 0;
        z-index: 10;

        > p {
            margin-block-end: 0;
        }
    }

    &-MinimalCart {
        // ...
    }

    &-ProductList {
        display: flex;
        flex-direction: column;
        padding-block: 10px;
    }

    &-TotalPriceWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-top: 1px solid var(--primary-divider-color);
    }

    &-TotalPriceLabel {
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }

    &-TotalPrice {
        font-weight: 700;
        font-size: 16px;
    }

    &-CartAction {
        text-align: center;

        @include desktop {
            .Button {
                width: 100%;
                max-width: 300px;
            }
        }
    }
}
