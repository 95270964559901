/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderTotals {
    margin-block-end: 0;

    &-Wrapper {
        margin-block: 0;
        background-color: transparent;
        border-top: 1px solid var(--primary-divider-color);


        tr {
            th, td {
                min-width: 0;
                padding: 10px;
            }

            th {
                padding-inline-start: 0;

                @include desktop {
                    text-align: start;
                }
            }

            td {
                white-space: nowrap;
                padding-inline-end: 0;
            }

            &:not(.MyAccountOrderTotals-GrandTotal) {
                th, td {
                    color: #797979;
                }

                th {
                    font-size: 12px;
                    font-weight: 700;
                }

                td {
                    font-size: 13px;
                    font-weight: 600;
                }
            }

            &.MyAccountOrderTotals-GrandTotal {
                th, td {
                    padding-block: 15px;
                }
            }
        }
    }

    &-GrandTotal {
        border-top: 1px solid var(--primary-divider-color);

        th {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }

        td {
            font-size: 16px;
            font-weight: 700;
        }
    }
}
