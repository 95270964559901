/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrder {
    &-BackLink {
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-weight: 500;
        font-size: 11px;
        color: var(--default-color);
    }

    &-FavoriteButton {
        position: absolute;
        inset-block-start: -35px;
        inset-inline-end: 16px;

        @include desktop {
            inset-block-start: 15px;
            inset-inline-end: 5px;
        }
    }

    &-Header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-block-end: 30px;

        @include mobile {
            .MyAccountOrderTimeline {
                margin-inline: -16px;
                order: -1;
            }

            .MyAccount-CustomerServicePush {
                margin-block-start: 0;
                padding-block: 5px;
            }
        }

        @include desktop {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin-block: 35px 0;

            .MyAccountOrderTimeline {
                flex-basis: 100%;
                margin-block-start: 20px;
            }

            .MyAccount-CustomerServicePush {
                flex-basis: 100%;
                margin-block: 20px 25px;
                padding-block: 10px;
            }
        }
    }

    &-CreationDate {
        margin: 0;
        font-size: 15px;
        font-weight: 300;
        text-align: center;

        @include mobile {
            order: -2;
        }

        @include desktop {
            font-size: 20px;
            text-align: start;
        }
    }

    &-Actions {
        --button-height: 40px;
        --button-hover-height: var(--button-height);
        --hollow-button-height: var(--button-height);
        --hollow-button-hover-height: var(--button-height);
        --button-font-size: 11px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        @include desktop {
            flex-grow: 1;
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &-Reorder {
        width: 100%;
        margin-inline-end: 0;
        font-weight: var(--button-font-weight);

        &.Button:hover {
            color: var(--button-hover-color);
        }

        @include desktop {
            width: auto;
        }
    }

    &-InvoiceButton {
        --hollow-button-border: var(--color-black);
        --hollow-button-color: var(--color-black);

        column-gap: 12px;

        &::before {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 19px;
            height: 24px;
            background: url($path-img + 'pdf.svg') no-repeat center;
            background-size: auto 100%;
        }
    }

    &-Content {
        @include mobile {
            padding-block-end: 30px;
        }

        @include desktop {
            $my-account-order-content-padding-block: 55px;

            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: calc(var(--content-wrapper-padding) * 2);
            padding-block: $my-account-order-content-padding-block;
            border-block: 1px solid var(--primary-divider-color);

            &::before {
                content: "";
                display: block;
                height: calc(100% - #{$my-account-order-content-padding-block} * 2);
                border-inline-start: 1px solid var(--primary-divider-color);
                position: absolute;
                inset-block-start: $my-account-order-content-padding-block;
                inset-inline-start: 50%;
            }
        }
    }
}
