/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --order-timeline-step-gap: 20px;
    --order-timeline-step-row-gap: 15px;
    --order-timeline-step-icon-width: 40px;
}

.MyAccountOrderTimeline {
    background-color: var(--secondary-light-color);
    padding: 25px;

    &-Steplist {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &-Step {
        display: flex;
        align-items: flex-start;
        column-gap: var(--order-timeline-step-gap);
        row-gap: var(--order-timeline-step-row-gap);

        &:not(:first-child) {
            &::before {
                content: "";
                display: block;
                height: 14px;
                border: {
                    style: dashed;
                    color: var(--color-black);
                    width: 0 0 0 1px;
                }

                position: absolute;
                inset-block-end: calc(100% + 3px);
                inset-inline-start: calc(var(--order-timeline-step-icon-width) / 2);
            }
        }

        &Icon {
            flex: 0 0 var(--order-timeline-step-icon-width);
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 35px;

            span {
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                $statutes: (
                    pending: (width: 33px, height: 28px),
                    pending_payment: (width: 33px, height: 28px),
                    canceled: (width: 33px, height: 25px),
                    processing: (width: 30px, height: 28px),
                    processing_shipped: (width: 34px, height: 24px),
                    processing_in_transit: (width: 30px, height: 32px),
                    processing_estimated_drop: (width: 39px, height: 31px),
                    processing_estimated_delivery: (width: 39px, height: 31px),
                    complete: (width: 39px, height: 31px)
                );

                @each $status, $sizes in $statutes {
                    $width: map-get($sizes, width);
                    $height: map-get($sizes, height);

                    &.#{$status} {
                        width: $width;
                        height: $height;
                        background-image: url($path-img + 'order-status/#{$status}.svg');
                    }
                }


                &.pending { // en attente paiement
                    // ...
                }

                &.pending_payment { // en attente paiement
                    // ...
                }

                &.canceled { // commande annulée
                    // ...
                }

                &.processing { // preparation
                    // ...
                }

                &.processing_shipped { // expedition
                    // ...
                }

                &.processing_in_transit { // transit
                    margin-inline-start: 9px;
                }

                &.processing_estimated_drop,
                &.processing_estimated_delivery,
                &.complete { // livraison
                    transform: translate(3px, 0);
                }
            }
        }

        &Content {
            // ...
        }

        &Label {
            font-weight: 700;
        }

        &EstimateMessage {
            margin-block-start: 3px;
            font-style: italic;
            font-size: 11px;
        }

        &_isActive {
            .MyAccountOrderTimeline-StepIcon {
                &::before {
                    content: "";
                    display: block;
                    width: 54px;
                    height: 59px;
                    background: url($path-img + 'order-step-shape.svg') no-repeat center;
                    background-size: contain;
                    opacity: percentage(1 / 3);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &-Shipment {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: .3em;
        margin-block: 0.75em;

        &Label {
            font-size: 13px;
            font-weight: 500;
        }

        // Hide shipment number if only one tracking link

        &:first-child:last-child {
            .MyAccountOrderTimeline-ShipmentLabel {
                display: none;
            }
        }
    }

    &-TrackingLink {
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: 0.15em;
    }

    @include desktop {
        min-height: 95px;

        &-Steplist {
            flex-direction: row;
            column-gap: 7%;
            justify-content: center;

            .MyAccountOrderTimeline-Step {
                flex-basis: 25%;
                flex-direction: column;
                align-items: center;
                text-align: center;

                &:not(:first-child) {
                    &::before {
                        width: 21%;
                        height: 0;
                        border-width: 1px 0 0 0;
                        inset-block-end: auto;
                        inset-block-start: 27px;
                        inset-inline-start: auto;
                        inset-inline-end: 107%;
                    }
                }

                &Icon {
                    scale: 1.2;
                }

                &EstimateMessage {
                    font-size: 10px;

                    br {
                        display: none;
                    }
                }
            }
        }
    }

    @media (min-width: 1580px) {
        &-Steplist {
            .MyAccountOrderTimeline-Step {
                flex-direction: row;
                align-items: flex-start;
                text-align: start;

                &Icon {
                    translate: 0 5px;
                }
            }
        }
    }
}
