/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountMyOrders {
    &-Tabs {
        display: flex;

        @include mobile {
            margin-block-start: 20px;
            margin-inline: -16px;
            border-block-start: 1px solid var(--primary-divider-color);
            background-color: var(--secondary-light-color);
        }

        @include desktop {
            border-block-end: 1px solid var(--primary-divider-color);
        }
    }

    /* &-YearWrapper {
        display: none;

        &:has(div.MyAccountOrderTableRow:not(.MyAccountOrderTableRow_isHidden)) {
            display: block;
        }
    } */

    &-Year {
        margin-block-end: 10px;
        font-size: 16px;

        &:not(:first-child) {
            margin-block-start: 25px;
        }
    }

    &-Table {
        > .TextPlaceholder {
            display: block;
            margin-block-end: 10px;

            &::after {
                display: block;
                height: 170px;

                @include desktop {
                    height: 185px;
                }
            }
        }
    }

    &-LoadMore {
        text-align: center;
    }

    &-Filters {
        display: flex;
        align-items: center;
        gap: 25px;
        min-height: 42px;
    }

    &-FilterBtn {
        @include filter-button();

        grid-column: auto;
    }

    &-ResetSection {
        .ResetAttributes {
            &-MobileTitle {
                display: none;
            }

            @include mobile {
                margin-block-end: 0;
            }
        }
    }

    &FilterPopup {
        .Popup-Main {
            display: flex;
            flex-direction: column;
            padding-block-end: 0;

            > *:last-child {
                margin-block-start: auto;
            }
        }
    }

    &-PopupAttributes {
        @include desktop {
            .ExpandableContent {
                &-Button {
                    padding-block: 15px;
                }

                &-Content {
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    margin-top: 0;

                    &_isContentExpanded {
                        opacity: 1;
                        max-height: 100%;
                    }
                }
            }
        }
    }

    &-PopupActions {
        padding: 15px;
        border-block-start: 1px solid var(--primary-divider-color);

        @include desktop {
            padding: 30px 0;
        }
    }

    &-PopupSubmitButton {
        width: 100%;
    }
}
