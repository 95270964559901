/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressTable {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: flex-start;
    grid-column-gap: 20px;
    padding-block: 25px;

    &-Heading {
        --h3-font-size: 18px;
        --h3-font-weight: 700;
        --h3-font-weight-mobile: 700;
        --h3-text-transform: none;
        --h3-text-transform-mobile: none;

        margin-block-start: 0;
    }

    .RadioButtonIcon {
        display: none;
    }

    address {
        ul {
            li {
                font-size: 14px;

                &:last-child {
                    margin-block-end: 0;
                }

                &::before {
                    display: none;
                }

                &[data-key="company"],
                &[data-key="name"],
                &[data-key="telephone"] {
                    font-weight: 600;
                }

                &[data-key="company"],
                &[data-key="name"] {
                    font-size: 16px;
                }

                &[data-key="company"] + li[data-key="name"] {
                    font-size: 14px;
                }

                &[data-key="telephone"] {
                    margin-block-start: 12px;
                }
            }
        }
    }

    &-Actions {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    .Button {
        &_modify {
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 0.15em;
        }

        &_delete {
            @include no-txt();

            flex-shrink: 0;
            display: inline-flex;
            background: transparent url('/style/images/delete-pink.svg') no-repeat center;
            background-size: contain;

            &,
            &:not([disabled]):hover {
                width: 15px;
                height: 16px;
                padding: 0;
                border-radius: 0;
            }
        }
    }

    &-QuickEdit {
        grid-column-start: span 2;
        margin-block-start: 10px;

        &Button {
            margin-block-start: .5em;
        }

        .Button {
            width: auto;
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 0.15em;
        }
    }
}
