/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountDocumentsForm {
    .FieldForm-CurrentFile {
        margin-block-start: 20px;

        &Link {
            font-weight: 500;
            text-decoration: underline;
            text-underline-offset: 0.25em;
        }
    }

    .GdprConsent {
        margin-block-start: 20px;
    }
}
