/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.LoginAccount {
    &-Header {
        margin-block-end: 25px;
        text-align: center;

        p {
            margin-block: .5em 0;
            font-size: 13px;
        }

        @include desktop {
            margin-block-end: 50px;
        }
    }

    &-InnerWrapper {
        text-align: center;

        h3 {
            margin-top: 10px;

            @include mobile {
                margin-bottom: 10px;
            }
        }

        h4 {
            text-align: inherit;
        }

        .Form {
            text-align: left;
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            grid-gap: initial;

            > div {
                flex-grow: 0;
                border: 6px solid var(--login-account-secondary-color);
                margin-inline: 0;
            }

            .LoginAccount-NewCustomerWrapper,
            .LoginAccount-SignInWrapper {
                flex-basis: calc(50% - 10px);
                padding: 30px 5% 75px;
            }

            .LoginAccount-OtherTypes {
                flex-basis: 100%;
                margin-block-start: 25px;
            }
        }

        @include wide-desktop {
            .LoginAccount-NewCustomerWrapper {
                flex-basis: calc(33.3333% - 10px);
            }

            .LoginAccount-SignInWrapper {
                flex-basis: calc(40% - 10px);
                text-align: left;
            }

            .LoginAccount-OtherTypes {
                flex-basis: 26.6667%;
                margin-top: 0;
                flex-direction: column;
            }
        }
    }

    &-NewCustomerWrapper {
        .Button {
            width: 100%;
        }

        @include desktop {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-inline: 0 20px;
        }
    }

    &-Additional {
        margin-block-start: 20px;
        padding-inline: 15px;
        text-align: start;

        h4 {
            font-weight: 700;
            text-align: center;
        }

        ul {
            li {
                display: flex;
                align-items: flex-start;
                font-size: 13px;

                &::before {
                    flex: 0 0 auto;
                    content: "";
                    display: block;
                    position: static;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                    margin-inline-end: 10px;
                    background: url($path-img + 'check-rounded.svg') no-repeat center;
                    background-size: contain;
                }
            }
        }
    }

    &-CustomerService {
        margin-block-start: 30px;

        .CustomerService {
            flex-direction: column;

            &-Title {
                margin: 0;
            }

            .Button {
                &_CustomerService {
                    --button-height: 34px;
                    --button-hover-height: 34px;

                    @include mobile {
                        width: auto;
                        margin-block-start: 10px;
                    }
                }
            }
        }
    }

    &-SignInWrapper {
        > p {
            @include mobile {
                font-size: 12px;
            }
        }

        form {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            justify-content: space-between;

            > * {
                flex-basis: 100%;
            }
        }

        .Field-Wrapper_type_checkbox,
        .MyAccountOverlay-ForgotPassword {
            flex-basis: auto;
            font-size: 12px;
        }

        @include mobile {
            margin-top: 20px;
            padding-top: 15px;
            border-top: 1px solid #BCBCBC;
        }

        @include desktop {
            margin-block-start: 0;
            margin-inline: 0;
            background-color: var(--login-account-secondary-color);
        }
    }

    &-OtherTypes {
        margin-block-start: 25px;
    }

    &-NewCustomerWrapper + .LoginAccount-OtherTypes {
        @include mobile {
            padding-block-start: 20px;
            border-block-start: 1px solid var(--primary-divider-color);
        }
    }
}
