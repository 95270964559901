/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccount {
    &-CustomerServicePush {
        margin-block-start: 20px;
        padding-block: 15px;

        &Title {
            max-width: 260px;
            margin-block: 0 0.75em;
            margin-inline: auto;
            font-size: 16px;
            font-weight: 300;
            text-transform: none;
            line-height: 1.3;
            text-align: center;
        }

        .CustomerService {
            .Button {
                width: auto;
                white-space: nowrap;
            }
        }

        @include mobile {
            .CustomerService {
                margin-block-end: 15px;

                &-Title {
                    max-width: 260px;
                    margin-inline: auto;
                }
            }
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: 40px;
            row-gap: 15px;

            &Title {
                max-width: none;
                margin-block-end: 0;
                margin-inline: 0 auto;
                padding-inline-end: 10px;
                font-size: 18px;
                text-align: start;
            }

            .CustomerService {
                flex-basis: auto;
                display: flex;
                justify-content: space-between;
                text-align: left;
            }
        }
    }

    &-TicketButton {
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: .15em;

        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 16px;
            margin-inline-end: 8px;
            background: url('/style/images/mail-pink.svg') no-repeat center;
            background-size: contain;
        }
    }
}
