/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderItemsTable {
    &-ProductsWrapper {
        @include desktop {
            max-width: 360px;
        }
    }

    &-Title {
        margin-block-end: 0.5em;
        font-size: 16px;
        font-weight: 300;

        @include desktop {
            font-size: 18px;
        }
    }

    &-OrderTitle {
        font-size: 12px;
        font-weight: 600;

        @include desktop {
            font-size: 14px;
        }
    }

    &-Products {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-block: 30px;
    }

    &-Totals {
        width: 100%;
        margin-block-end: 0;
    }
}
