/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderInformation {
    &-Wrapper {
        margin-block-start: 0;
    }

    &-Information {
        row-gap: 0;

        @include desktop {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &-Column {
        margin-block-end: 0;
        padding-block: 25px 0;
        border-block-start: 1px solid var(--primary-divider-color);

        @include desktop {
            width: 100%;
            max-width: 405px;
            margin-inline-end: 0;
            padding-block: 35px 20px;

            &:first-child {
                padding-block-start: 0;
                border-block-start-width: 0;
            }
        }
    }

    &-ColumnTitle {
        margin-block-end: 15px;

        strong {
            font-weight: 300;
            font-size: 16px;
        }

        @include mobile {
            all: unset;
        }

        @include desktop {
            strong {
                font-size: 18px;
            }
        }
    }

    &-ShippingMethod {
        margin-block-start: 15px;

        @include mobile {
            padding-inline-start: unset;
        }

        @include desktop {
            margin-block-start: 45px;
        }
    }

    &-ShippingAddress {
        @include desktop {
            margin-inline-start: 30%;
        }
    }

    &-PaymentMethod {
        margin-block-start: 20px;

        @include mobile {
            padding-inline-start: unset;
        }
    }
}
