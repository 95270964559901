/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountConfirmIdForm {
    &-Description {
        text-align: center;
    }

    &-SkipButton {
        margin-block-end: 10px;
        font-weight: 500;
        text-decoration: underline;
        text-underline-offset: .15em;
    }

    &-SkipWarning {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;

        &::before {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 29px;
            height: 29px;
            background: url(/style/images/info.svg) no-repeat center;
            background-size: contain;
            margin-right: 10px;
        }
    }
}
