/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCreateAccount {
    > h3 {
        text-align: center;
    }

    button {
        margin-block-start: 20px;
    }

    > div {
        flex: 1;
        margin: 0 10px;
    }

    button,
    input {
        min-width: 250px;

        @include mobile {
            width: 100%;
        }

        @include desktop {
            width: 100%;
        }

        &[readonly] {
            background-color: #f5f5f5;

            &:focus {
                border-color: var(--input-border-color);
            }
        }
    }

    p {
        margin-block-end: 20px;
    }

    .Field_isValid {
        input,
        textarea,
        select {
            border-color: var(--input-border-color);
        }
    }

    .StoreSwitcher {
        margin-block-start: 24px;

        @include desktop() {
            margin-inline-start: 0;

            .Field-Wrapper {
                flex: 1 0 auto;
            }
        }
    }

    .react-tel-input {
        .form-control {
            @include desktop() {
                max-width: calc(50% - 8px);
            }
        }
    }

    &-RecapStep {
        > h3 {
            text-align: center;

            &::before {
                content: "";
                display: block;
                width: 25px;
                height: 18px;
                margin-inline: auto;
                margin-block-end: 15px;
                background: url($path-img + 'check-pink.svg') no-repeat center;
                background-size: contain;
            }
        }

        text-align: center;
    }

    & &-Disclaimer {
        --paragraph-font-weight: 300;
        --paragraph-font-size: 12px;
        --paragraph-line-height: 1.4;
        --paragraph-font-weight-mobile: var(--paragraph-font-weight);
        --paragraph-font-size-mobile: var(--paragraph-font-size);
        --paragraph-line-height-mobile: var(--paragraph-line-height);

        @include full-page-width();

        margin-block-start: #{fluid(50px, 80px)};

        .CmsBlock-Wrapper {
            max-width: 1000px;
            margin-inline: auto;
            padding-inline: 20px;
            text-align: left;
        }

        p {
            margin-block-end: .75em;
        }
    }
}

.MyAccountOverlay {
    &-Legend {
        &_isHidden {
            display: none;
        }

        &.type {
            // label style

            > p {
                margin-block-end: 0;
                font-size: 12px;
                font-weight: 400;
                padding-block-end: 8px;
            }

            .Field-Wrapper_radios {
                display: flex;
                flex-wrap: wrap;
            }

            .Field-Wrapper {
                margin-inline-end: 30px;
            }

            .Field {
                margin-block: 0 10px;

                label {
                    font-size: 12px;
                }
            }
        }
    }

    &-PasswordBlock {
        .Field-ErrorMessages + .Field-SubLabelContainer {
            display: none;
        }
    }
}

.MyAccountOverlayInfo {
    &-Legend {
        padding: 0.5em;

        &_isHidden {
            display: none;
        }
    }
}
