/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCompanySearchForm {
    .MyAccountCompanyAutosuggest {
        margin-block-start: 15px;
    }

    &-Skip {
        margin-block-start: 20px;

        p {
            margin-block-end: 5px !important;
            font-size: 14px;
            font-weight: 600;
        }
    }

    &-SkipButton {
        width: auto !important;
        margin-block-start: 0 !important;
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        text-underline-offset: 0.15em;
    }

    &-Buttons {
        margin-block-start: 30px;
        text-align: center;
    }

    &-SubmitButton {
        margin-block-start: 0 !important;
    }
}
