/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CreateAccount {
    overflow: hidden;

    &_type {
        &_pro,
        &_employee {
            //--button-background: var(--secondary-base-color);
            //--button-hover-background: var(--secondary-dark-color);
            //--button-hover-border: var(--secondary-dark-color);
            $optins-background: darken($pro-light-color, 5%);
            --my-account-overlay-optins-background: #{$optins-background};

            background-color: var(--pro-light-color);
        }

        &_student {
            $optins-background: darken($student-light-color, 5%);
            --my-account-overlay-optins-background: #{$optins-background};

            background-color: var(--student-light-color);
        }
    }

    &-BackLink {
        @include back-link();
    }

    &-InnerWrapper {
        > h3 {
            margin-block-start: 0;
            text-align: center;
        }

        input {
            &[type='checkbox'],
            &[type='radio'] {
                min-width: 0;
                width: var(--imported_checkboxes_width);
            }
        }

        @include desktop() {
            display: block;
            max-width: 720px;
            margin: 0 auto;
        }
    }
}
