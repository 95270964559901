/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccount {
    &-Wrapper {
        @include desktop {
            grid-template-columns: minmax(250px, 24%) auto;
            // grid-template-rows: auto 1fr;
            grid-column-gap: 5%;
            align-items: flex-start; // for sidebar position sticky
            padding-block: 30px;
        }

        @include wide-desktop {
            grid-column-gap: 10%;
        }
    }

    &-Sidebar {
        @include desktop {
            position: sticky;
            top: 15px;
        }
    }

    &-Heading {
        font-weight: 300;

        @include desktop {
            font-size: 40px;
            margin-block-end: 20px;
        }

        @include mobile {
            display: block;
            font-weight: 800;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &-Hello {
        margin-block-start: 0;
        font-weight: 800;
        text-transform: uppercase;

        @include mobile {
            margin-block-start: 25px;
            text-align: center;
        }

        @include desktop {
            display: flex;
            align-items: center;
            font-size: var(--h2-font-size-mobile);

            &_is_pro,
            &_is_student {
                .MyAccount-HelloIcon {
                    flex-shrink: 0;
                    display: block;
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    margin-inline-end: 15px;

                    &::before {
                        @include full-cover(true);

                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 48px auto;
                    }
                }
            }

            &_is_pro {
                .MyAccount-HelloIcon {
                    background-color: var(--pro-dark-color);

                    &::before {
                        background-image: url($path-img + 'pro.svg');
                    }
                }
            }

            &_is_student {
                .MyAccount-HelloIcon {
                    background-color: var(--student-dark-color);

                    &::before {
                        background-image: url($path-img + 'student.svg');
                    }
                }
            }
        }
    }

    &-Back {
        padding-block: 15px;
        padding-inline: var(--content-wrapper-padding-mobile);

        &Link {
            display: inline-flex;
            align-items: center;

            .ChevronIcon {
                margin-inline-end: 6px;
            }
        }
    }

    &-TabContent {
        display: flex;
        flex-direction: column;
        border: none;

        @include desktop {
            padding: 0;
            overflow: visible;
        }

        &_activeTab {
            &_customer-service {
                .MyAccount-Heading {
                    @include mobile {
                        margin-block-end: 15px;
                    }

                    @include desktop {
                        margin-block-end: 10px;
                    }
                }
            }

            &_my-orders {
                .MyAccount-Heading {
                    @include mobile {
                        margin-block-end: 0;
                    }
                }
            }
        }
    }

    &-WrapperPushPages {
        @include mobile {
            padding-inline: 0;
        }
    }

    &-PushPages {
        @include mobile {
            padding-block: 2em;
            background-color: var(--secondary-light-color);

            &_is_pro {
                &::before {
                    @include full-page-cover(true);

                    z-index: -1;
                    background-color: var(--pro-light-color);
                }
            }

            &_is_student {
                &::before {
                    @include full-page-cover(true);

                    z-index: -1;
                    background-color: var(--student-light-color);
                }
            }
        }

        @include desktop {
            grid-column-start: span 2;
            padding-block: 60px;
        }
    }
}
