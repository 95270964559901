/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCompanyForm {
    &-Field {
        &_isDisabled {
            opacity: .5;

            &.Field_hasError {
                input {
                    border-color: var(--input-border-color);
                }
            }

            & + .Field-ErrorMessages {
                display: none;
            }
        }

        &_hasError {
            input {
                border-color: var(--primary-error-color) !important;
            }
        }
    }

    &-NoSiretReasonWarning {
        p {
            display: flex;
            align-items: center;
            margin-block: 10px 0 !important;
            padding-inline: 20px;
            font-size: 14px;
            font-weight: 500;

            &::before {
                flex: 0 0 auto;
                content: "";
                display: block;
                width: 29px;
                height: 29px;
                background: url($path-img + 'info.svg') no-repeat center;
                background-size: contain;
                margin-right: 10px;
            }
        }
    }

    &-SiretWrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;

        > * {
            flex: 1 1 auto;

            &:last-child {
                flex: 0 0 auto;
            }
        }

        .Field_type_checkbox {
            align-items: center;
            min-height: var(--input-height);

            label {
                font-size: 12px;
            }
        }
    }

    &-StreetWrapper {
        .MyAccountCompanyForm-Legend {
            display: none;

            &_isVisible {
                display: block;
            }
        }
    }

    &-StreetToogleButton {
        display: none;
        margin-block-start: 10px !important;

        &.Button_isVisible {
            display: inline-flex;
        }
    }

    &-Buttons {
        margin-block-start: 30px;
        text-align: center;
    }

    .FieldPostcodeCity {
        input {
            min-width: 0;
        }
    }
}
