/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@mixin MyAccountTabListItemButtonPadding {
    padding-block: 18px;
    padding-inline: 45px 0;

    &::before {
        content: "";
        display: block;
        position: absolute;
        inset-inline-start: 15px;
        inset-block-start: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center;
    }

    @include mobile {
        padding-inline: 60px 15px;

        &::before {
            inset-inline-start: 30px;
        }
    }

    @include desktop {
        width: 100%;
    }
}

@mixin MyAccountTabListItemButtonText {
    font-weight: 500;
    text-transform: uppercase;
}

$path-img-account: $path-img + 'account/';

.MyAccountTabListItem {
    border-block-end: 1px solid var(--primary-divider-color);

    &:hover {
        .MyAccountTabListItem-Button {
            font-weight: 400;
        }
    }

    &-Button {
        @include MyAccountTabListItemButtonPadding;
        @include MyAccountTabListItemButtonText;

        .ChevronIcon {
            width: 20px;
        }

        &.my-orders::before {
            width: 23px;
            height: 23px;
            background-image: url($path-img-account + 'account-orders.svg');
        }

        &.quick-order::before {
            width: 29px;
            height: 26px;
            background-image: url($path-img-account + 'account-quickorder.svg');
            margin-inline-start: 3px;
        }

        &.my-wishlist::before {
            width: 22px;
            height: 20px;
            background-image: url($path-img-account + 'account-wishlist.svg');
        }

        &.customer-service::before {
            width: 32px;
            height: 26px;
            background-image: url($path-img-account + 'account-service.svg');
        }

        &.logout::before {
            width: 13px;
            height: 13px;
            background-image: url($path-img-account + 'account-logout.svg');
        }
    }

    & &-Button {
        &:hover {
            font-weight: 600 !important;
        }
    }

    &_isActive &-Button {
        &,
        &:hover {
            font-weight: 700 !important;
        }
    }

    &-ExpandableContent {
        &Button {
            @include MyAccountTabListItemButtonPadding;

            .AddIcon,
            .MinusIcon {
                width: 20px;
                height: 20px;
            }

            &.my-profile::before {
                width: 22px;
                height: 28px;
                background-image: url($path-img-account + 'account-profile.svg');
            }
        }

        &Heading {
            @include MyAccountTabListItemButtonText;

            font-size: 14px;
        }

        &Content {
            ul {
                margin-block-end: 20px;
            }

            @include desktop {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                margin-top: 0;

                &_isContentExpanded {
                    opacity: 1;
                    max-height: 100%;
                }
            }
        }
    }

    &_isGroup {
        .MyAccountTabListItem {
            border-block-end-width: 0;

            &-Button {
                padding-block: 10px;
                font-weight: 400;
                text-transform: none;

                .ChevronIcon {
                    display: none;
                }
            }
        }
    }
}
