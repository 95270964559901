/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressBook {
    &-Sections {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 30px;
    }

    &-Section {
        > h2 {
            margin-block-start: 0;
            font-size: 16px;
            font-weight: 300;
        }

        &_default {
            .MyAccountAddressBook-List {
                background-color: var(--secondary-light-color);

                @include mobile {
                    &::before {
                        @include full-cover(true);

                        width: calc(100% + 32px);
                        inset-inline-start: -16px;
                        background-color: var(--secondary-light-color);
                    }
                }
            }
        }

        &_other {
            > h2 {
                margin-block-end: 0;
            }
        }
    }

    &-List {
        > * {
            &:not(:first-child) {
                border-block-start: 1px solid var(--primary-divider-color);
            }
        }
    }

    button + button {
        margin-inline-start: 0;

        @include mobile {
            margin-block-start: 0;
        }
    }

    &-Button {
        @include desktop {
            position: relative;
            inset-block-start: auto;
            inset-inline-end: auto;
        }
    }

    @include desktop {
        &-Section > h2,
        &-List {
            padding-inline: 15px;
        }
    }
}
