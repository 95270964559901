/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountAddressForm {
    .Field-Wrapper_type_checkbox {
        &:first-child {
            .Field {
                margin-block-start: 0;
            }
        }
    }

    .FieldForm-Actions {
        margin-block-start: 25px;
        text-align: center;

        .Button {
            width: 100%;
        }
    }
}
