/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOrderItemsTableRow {
    display: grid;
    grid-template-columns: 85px calc(100% - 100px);
    grid-column-gap: 15px;

    &-Visual {
        // ...
    }

    &-Picture {
        aspect-ratio: var(--aspect-ratio-product-image);
    }

    &-Content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-block-start: 5px;
    }

    &-Name {
        font-size: 15px;
        font-weight: 700;
    }

    &-SkuAndContenance {
        display: flex;
        gap: 0.3em;
        font-size: 12px;
        // font-weight: 300;
        color: #676767;
    }

    &-Contenance {
        &::after {
            content: "-";
            margin-inline-start: .3em;
        }
    }

    &-WrapperPrice {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-block-start: auto;
    }

    &-Qty {
        font-size: 10px;
        font-weight: 700;
        letter-spacing: .1em;
        text-transform: uppercase;
    }

    &-QtyList {
        .CheckoutSuccess & {
            display: none;
        }
    }

    &-Option {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
        display: flex;
        gap: 4px;
        color: var(--secondary-dark-color);
        font-size: 12px;

        &_isBundle {
            flex-direction: column;
            gap: 0;
        }

        span,
        div {
            white-space: normal;
        }
    }
}
