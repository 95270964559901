/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountBanner {
    padding: .65em 1em;
    font-size: clamp(10px, calc(0.34vw + 8.73px), 14px);
    font-weight: 500;
    color: var(--color-white);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .2em;

    @include mobile {
        font-weight: 600;
    }

    &_isPro {
        background-color: var(--pro-dark-color);
    }

    &_isStudent {
        background-color: var(--student-dark-color);
    }
}
