/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */


/*** COLORS ***/

$pink:        #EDC1BD;
$pink-dark:   #B56F68;
$brown:       #DEC7B5;
$brown-light: #F7F1ED;
$brown-dark:  #533A36;
$beige:       #F0E6DE;
$blue:        #BAC1E0;
$blue-dark:   #ACBDDE;
$pink2:       #E7BCB8;
$mauve:       #C8A0C7;

$white: #fff;
$black: #0a0903;
$default-primary-base-color: $pink;
$default-primary-dark-color: $pink-dark;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: $brown;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: $brown-light;
$pro-base-color: lighten($mauve, 10%);
$pro-light-color: lighten($mauve, 25%);
$pro-dark-color: $mauve;
$student-base-color: $blue;
$student-light-color: lighten($student-base-color, 15%);
$student-dark-color: $blue-dark;


/*** FONTS ***/

$f-times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$f-times: Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$f-georgia: Georgia, "Times New Roman", "DejaVu Serif", serif;
$f-verdana: Verdana, Tahoma, "DejaVu Sans", sans-serif;
$f-tahoma: Tahoma, Verdana, "DejaVu Sans", sans-serif;
$f-helvetica: 'Helvetica Neue', Verdana, Arial, sans-serif;
$f-arial: Arial, Helvetica, "Nimbus Sans L", sans-serif;
$f-courier: "Courier New", "DejaVu Sans Mono", monospace, sans-serif;
$f-impact: Impact, 'Trebuchet MS', Tahoma, sans-serif;
$f-trebuchet: 'Trebuchet MS', Tahoma, sans-serif;

$font-muli: 'Muli', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;

$font-hurricane: 'Hurricane', sans-serif;

/*** VARIABLES TO USE ***/

$f-serif: $f-times;
$f-sans-serif: $font-montserrat;
$f-monospace: $f-courier;

$f-default: $f-sans-serif;

$f-title: $f-sans-serif;
$f-special: $font-hurricane;
$f-button: $f-sans-serif;


$font-standard-size: 62.5%;


/*** PATHS ***/

$path-img: '/style/images/';

