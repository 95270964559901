/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountOtherTypes {
    display: flex;

    &-Block {
        --item-padding: 15px;

        flex-basis: 50%;
        border: {
            style: solid;
            color: #CDCDCD;
            width: 0;
        }

        &:nth-child(odd) {
            padding-right: var(--item-padding);
        }

        &:nth-child(even) {
            padding-left: var(--item-padding);
            border-left-width: 1px;
        }

        &:first-child:last-child {
            flex-basis: 100%;
        }

        @include desktop {
            --item-padding: 5%;

            display: flex;
            padding: var(--item-padding);
            border-color: var(--login-account-secondary-color);

            &:nth-child(odd) {
                padding-right: var(--item-padding);
                border-right-width: 3px;
            }

            &:nth-child(even) {
                padding-left: var(--item-padding);
                border-left-width: 3px;
            }

            &:first-child:last-child {
                border-inline-width: 0;
            }
        }

        @include wide-desktop {
            --item-padding: 10%;

            align-items: center;

            &:nth-child(odd) {
                border-right-width: 0;
                border-bottom-width: 3px;
            }

            &:nth-child(even) {
                border-left-width: 0;
                border-top-width: 3px;
            }

            &:first-child:last-child {
                border-block-width: 0;
            }
        }
    }

    &-BlockInner {
        display: flex;
        flex-direction: column;
        text-align: center;

        a {
            margin-top: auto;
        }

        @include mobile {
            .UserIcon {
                display: none;
            }
        }

        @include desktop {
            align-items: flex-start;
            justify-content: center;
            padding-left: 75px;
            text-align: left;

            .UserIcon {
                width: 45px;
                height: 45px;
                position: absolute;
                top: 0;
                left: 0;
                cursor: default;

                &:hover {
                    fill: var(--color-black);
                }
            }

            .student & {
                .UserIcon {
                    height: 40px;
                }
            }

            .private & {
                .UserIcon {
                    height: 32px;
                }
            }
        }
    }

    &-BlockTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 10px;
        font-weight: 700;
        text-transform: uppercase;

        @include mobile {
            min-height: 2.6em;
            font-size: 13px;
        }

        @include desktop {
            justify-content: flex-start;
        }
    }

    &-BlockDesc {
        margin-bottom: 15px;
        font-size: 12px;

        @include desktop {
            font-size: 14px;
        }
    }

    &-BlockLink {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: .15em;
    }
}
