/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.MyAccountCompanyAutosuggest {
    --loader-scale: .7;

    &-Suggestion {
        &Name {
            // ...
        }

        &Ape {
            font-size: 12px;
        }

        &Location {
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .Loader {
        width: 40px;
        top: 5px;
        bottom: 1px;
        right: 10px;
        left: auto;
    }
}
