/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --order-table-row-background: #{$beige};
    $beige-lightened: lighten($beige, 5%);
    --order-table-row-cancelled-background: #{$beige-lightened};
}

.MyAccountOrderTableRow {
    --order-timeline-step-gap: 10px;

    height: auto;
    margin-block-end: 10px;
    padding-block: 18px 15px;
    padding-inline: 15px;

    @include desktop {
        --order-timeline-step-icon-width: 50px;
        --order-timeline-step-gap: 15px;

        padding-inline: 20px;
    }

    &,
    &:hover {
        background-color: var(--order-table-row-background);
    }

    &_isCancelled {
        &,
        &:hover {
            background-color: var(--order-table-row-cancelled-background);
        }
    }

    &_isHidden {
        display: none;
    }

    &:focus-visible {
        outline: 1px dotted var(--primary-dark-color);
    }

    &-Content {
        margin-block-start: 15px;

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 210px;
            grid-gap: 20px;
            align-items: center;
            margin-block-start: 25px;
        }
    }

    &-Products {
        display: flex;
        gap: 5px;
    }

    &-Product {
        width: 75px;
    }

    &-Image {
        display: block;
        height: 0;
        padding-block-end: var(--ratio-product-image);

        .Image-Image {
            width: 100%;
        }
    }

    &-MoreProducts {
        width: 75px;

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;

            &::before {
                content: "+";
                display: block;
                width: 24px;
                height: 24px;
                margin-block-end: 10px;
                border: 1px solid var(--color-black);
                border-radius: 50%;
                font-size: 16px;
                text-align: center;
                line-height: 26px;
            }
        }
    }

    &-Number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-start: -17px;
        margin-inline-start: calc(var(--order-timeline-step-icon-width) + var(--order-timeline-step-gap));
        font-size: 15px;

        .ChevronIcon {
            flex-shrink: 0;
            margin-inline-start: 5px;
            margin-inline-end: -7px;

            @include desktop {
                display: none;
            }
        }

        @include desktop {
            font-size: 17px;
        }
    }

    .MyAccountOrderTimeline-Step {
        &Content {
            padding-block-end: 17px;
        }

        &Date {
            @include mobile {
                font-size: .9em;
                font-weight: 300;
            }

            @include desktop {
                margin-bottom: 0.5em;
            }
        }

        @include mobile {
            &_hasEstimatedDate {
                .MyAccountOrderTimeline-StepContent {
                    padding-block-end: 0;
                }

                & + .MyAccountOrderTableRow-Number {
                    margin-block-start: 3px;
                }
            }
        }

        @include desktop {
            &Icon {
                min-height: 40px;

                span {
                    transform: scale(1.1);
                }
            }

            &Label {
                font-size: 1.1em;
            }
        }
    }

    &-FavoriteButton {
        position: absolute;
        inset-block-start: 10px;
        inset-inline-end: 10px;
    }

    &-OrderLink {
        font-weight: 500;
        color: var(--link-color);
        text-decoration: underline;
    }
}
