/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/fonts';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

/**
 * FIX: Complete override to remove global redefining of --link-color & --link-hover
 */

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}

.MyAccountDownloadTableRow {
    //--link-color: var(--primary-base-color);
    //--link-hover: var(--primary-dark-color);
    height: 36px;

    td,
    th {
        @include mobile {
            width: 100%;
        }
    }

    &-Link {
        display: block;
    }

    &-Status {
        text-transform: capitalize;
    }

    &-DownloadLink {
        margin-inline-start: 8px;
    }
}
